import React from 'react';
import get from 'lodash/get';
import { mapRange, roundHalf } from '@rentivo/gatsby-core/src/utils/numbers';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/property/PropertyReviews/components/Review/messages';
import { getClosestContentObjByLocale } from '@rentivo/gatsby-core/src/utils/getters';
import dayjs from 'dayjs';
import TruncateText from '@rentivo/gatsby-core/src/components/ui/TruncateText';
import {
  StyledReviewRatingAverage
} from '@rentivo/gatsby-core/src/components/property/PropertyReviews/styles';
import { Heading, Box, Flex, Text } from '@chakra-ui/react';
import Rate from '@rentivo/gatsby-core/src/components/ui/Rate';

const ReviewComponent = ({review, locale, lines, ratingOutOf}) => {
  const { formatMessage } = useIntl();

  const reviewerName = get(review, 'reviewerName', formatMessage(messages.anonymous));
  const stayedAt = get(review, 'stayedAt', null);
  const stayedAtMo = dayjs(stayedAt, 'YYYY-MM-DD');
  const stayDuration = get(review, 'stayDuration', null);

  const rating = get(review, 'rating', null);
  const score = get(rating, 'score', null);
  const max = get(rating, 'max', null);
  const reviewArray = get(review, 'review', []);
  const localisedReview = getClosestContentObjByLocale(reviewArray, locale);
  const title = get(localisedReview, 'title', null);
  const content = get(localisedReview, 'content');

  return (
    <Box
      p={4}
      _odd={{
        bg: 'gray.100'
      }}
    >
      {(title) && <Heading as="h3" fontSize="lg" mb={2} dangerouslySetInnerHTML={{__html: title}}/>}
      {(score !== 0 && max !== 0) && (
        <Box fontWeight="semibold">
          <Rate disabled allowHalf defaultValue={roundHalf(mapRange(score, 0, max, 0, 5))} />
          <StyledReviewRatingAverage>{mapRange(score, 0, max, 0, ratingOutOf).toFixed(1)} / {ratingOutOf.toFixed(1)}</StyledReviewRatingAverage>
        </Box>
      )}
      {(content) && (
        <Box mt={2}>
          <TruncateText
            lines={lines}
            text={content}
          />
        </Box>
      )}
      {(reviewerName || stayDuration || stayedAt) && (
        <Flex justify="space-between" mt={2} color="textLight">
          {(reviewerName) && <Text as="div" fontStyle="italic">— {reviewerName}</Text>}
          {stayedAt || stayDuration && (
            <Text as="div">
              {(stayedAt) && (
                <Text as="span">
                  — <FormattedMessage {...messages.stayed} />&nbsp;
                  {(stayedAtMo._isValid) ? <FormattedDate value={stayedAt.valueOf()} year="numeric" month="short" day="numeric"/> : <>{stayedAt}</> }&nbsp;
                </Text>
              )}
              {(stayDuration) && (
                <Text as="span" className="duration">
                  <FormattedMessage {...messages.duration} values={{value: stayDuration}}/>
                </Text>
              )}
            </Text>
          )}
        </Flex>
      )}
    </Box>
  );
};

export default ReviewComponent;
