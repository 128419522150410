import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/property/PropertyReviews/messages';
import Paginate from '@rentivo/gatsby-core/src/components/ui/Paginate';
import ReviewSummary from '@rentivo/gatsby-core/src/components/property/PropertyReviews/components/ReviewSummary';
import Review from '@rentivo/gatsby-core/src/components/property/PropertyReviews/components/Review';
import PropertyComponentTitle from '@rentivo/gatsby-core/src/components/property/PropertyComponentTitle';
import { Box } from '@chakra-ui/react';

const PropertyReviewsComponent = ({wrapper: Wrapper, reviews: rawReviews, lines, locale, averageRating, showTitle, title, perPage, ratingOutOf, className, location: { pathname }}) => {
  const ref = useRef(null);
  if(!rawReviews || rawReviews.length === 0) return null;
  const reviewsWithData = rawReviews.filter(r => r.reviewerName);
  if(!reviewsWithData || reviewsWithData.length === 0) return null;

  const [reviews] = useState(rawReviews);
  if(!reviews.length) return null;

  const [page, setPage] = useState(1);
  const offset =  (page * perPage) - perPage;

  const onChange = useCallback(({selected}) => {
    if(ref.current) {
      setPage(selected + 1);
    } else {
      ref.current = true;
    }
  }, [ref]);

  const totalPages = (reviews.length <= perPage) ? 1 : Math.ceil(reviews.length / perPage);
  const reviewsOnPage = reviews.filter((r, i) => (i >= offset && i < (offset + perPage)));

  return (
    <Wrapper>
      <Box className={className}>
        {showTitle && <PropertyComponentTitle>{title ? title : <FormattedMessage {...messages.title} values={{reviewCount: reviews.length}}/>}</PropertyComponentTitle>}
        <ReviewSummary
          averageRating={averageRating}
          offset={offset}
          reviews={reviews}
          reviewsOnPage={reviewsOnPage}
          page={page}
          perPage={perPage}
          ratingOutOf={ratingOutOf}
        />
        <Box>
          {reviewsOnPage.map(review => <Review key={review.reviewerName} lines={lines} locale={locale} ratingOutOf={ratingOutOf} review={review}/>)}
        </Box>
        <Box mt={4}>
          <Paginate
            initialPage={page - 1}
            forcePage={page - 1}
            pageCount={totalPages}
            pageRangeDisplayed={4}
            marginPagesDisplayed={0}
            onPageChange={onChange}
            hrefBuilder={(page) => {
              return `${pathname}#reviews_page_${page}`;
            }}
          />
        </Box>
      </Box>
    </Wrapper>
  );
};

export default PropertyReviewsComponent;
