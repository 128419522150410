import { defineMessages } from 'react-intl';
import React from 'react';

export const scope = 'containers.PricingProvider.MobilePricingWidget';

export default defineMessages({
  buttonLabel: {
    id: `${scope}.buttonLabel`,
    defaultMessage: 'Book',
  }
});
