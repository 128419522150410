import styled from '@emotion/styled';

export const StyledMobilePricingWidget = styled.div`
  background-color: white;
  z-index: 999;
  padding: ${props => props.theme['padding-sm']};
  box-shadow: ${props => props.theme['box-shadow-base']};
  position: fixed;
  bottom:0;
  width: 100%;
  left:0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  border-top:1px solid ${props => props.theme['border-color-base']};
`;
