import React from 'react';
import { roundHalf } from '@rentivo/gatsby-core/src/utils/numbers';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/property/PropertyReviews/components/ReviewSummary/messages';
import {
  StyledReviewRatingAverage
} from '@rentivo/gatsby-core/src/components/property/PropertyReviews/styles';
import { Flex, Box } from '@chakra-ui/react';
import Rate from '@rentivo/gatsby-core/src/components/ui/Rate';

const ReviewSummaryComponent = ({reviews, reviewsOnPage, offset, averageRating, page, perPage, ratingOutOf}) => {

  const to = offset + 1;
  const from = reviewsOnPage.length + to - 1;
  const total = reviews.length;


  return (
    <Flex
      justify="space-between"
      mt={-3}
      mb={4}
    >
      {(averageRating !== 0) && (
        <Box fontWeight="medium">
          <FormattedMessage {...messages.averageRating}/>
          <Rate disabled allowHalf defaultValue={roundHalf(averageRating)} />
          <StyledReviewRatingAverage>
            {averageRating} / {ratingOutOf.toFixed(1)}
          </StyledReviewRatingAverage>
        </Box>
      )}
      <Box display={{base: 'none', lg: 'block'}}>
        <FormattedMessage {...messages.summaryPage} values={{perPage, to, from, total}}/>
      </Box>
    </Flex>
  );
};

export default ReviewSummaryComponent;
