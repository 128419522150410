import { compose } from 'redux'
import { createSelector } from 'reselect'
import PropertyFeefo from '@rentivo/gatsby-core/src/components/property/PropertyFeefo/component'
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext'
import { selectProperty } from '@rentivo/gatsby-core/src/selectors/property'
import get from 'lodash/get'
import { selectFeefoSkuPathPriceConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig'
import { connect } from 'react-redux'

const defaultProps = {
  showTitle: true,
  title: null,
  wrapper: ({ children }) => children,
}

const propertySelector = (state, { feefoSkuPath }) => createSelector(
  selectProperty,
  (property) => {
    return {
      feefoSku: feefoSkuPath ? get(property, feefoSkuPath, null) : null,
    }
  }
)(state);

const mapStateToProps = (_state, _props) =>
  createSelector(selectFeefoSkuPathPriceConfig, feefoSkuPath => {
    return {
      feefoSkuPath,
    }
  })

const enhance = compose(
  connect(mapStateToProps),
  withProperty(propertySelector)
)

PropertyFeefo.defaultProps = defaultProps

export default enhance(PropertyFeefo);
