import React from 'react';
import {
  chakra,
  Box,
  Flex,
  VStack
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/property/PropertyFeefo/messages';
import PropertyComponentTitle from '@rentivo/gatsby-core/src/components/property/PropertyComponentTitle';

const FeefoComponent = ({wrapper: Wrapper, feefoSku, showTitle, title, className }) => {
  if(!feefoSku) return null;
  // <script type="text/javascript" src="https://api.feefo.com/api/javascript/italian-breaks" async/>
  return (
    <Wrapper styleProps={{bg: 'transparent', boxShadow: 'none'}}>
      <script type="text/javascript" src="https://api.feefo.com/api/javascript/italian-breaks" async=""></script>
      <div id="feefo-product-review-widgetId" class="feefo-review-widget-product" data-product-sku={feefoSku}></div>
    </Wrapper>
  );
};

export default FeefoComponent;
