import React from 'react';
import {
    StyledPropertyOwner
} from '@rentivo/gatsby-core/src/components/property/PropertyOwner/styles';
import {FormattedMessage} from 'react-intl';
import messages from '@rentivo/gatsby-core/src/components/property/PropertyOwner/messages';
import PropertyComponentTitle from '@rentivo/gatsby-core/src/components/property/PropertyComponentTitle';
import {Box, chakra, Heading, Text} from "@chakra-ui/react";
import AwesomeIcon from "../../ui/AwesomeIcon";
import {faMailbox, faPhone} from "@fortawesome/pro-light-svg-icons";
import {Button, LocaleLink} from "../../../../index";
import {faSensorAlert} from "@fortawesome/pro-solid-svg-icons";

const paddingSides = {
    pl: 4,
    pr: 4
};

const hasBrandCompanyName = marketing => {
    return hasMarketing(marketing) && marketing['agent']['brandCompanyName'];
};

const hasMarketing = marketing => {
    return marketing && marketing['agent'];
};

const PropertyOwnerComponent = ({wrapper: Wrapper, property, marketing, showHeader, title, className}) => {
    const canShowHeader = showHeader && hasBrandCompanyName(marketing);

    function getSourceTagValue(data) {
        if (data.tags && Array.isArray(data.tags)) {
            for (let tagObj of data.tags) {
                if (tagObj.tag === "source") {
                    return tagObj.value;
                }
            }
        }

        return null;
    }

    const specialNotesIntegrationMap = {
        //"supercontrolipm": "The pricing on this property is non-commission. Please add the price displayed to the commission calculator before quoting. Ensure the supplier reflects the uplifted amount on their invoice. Apologise for the inconvenience."
    };

    const specialNotes = specialNotesIntegrationMap[getSourceTagValue(property)] || null;

    const processBiography = (text) => {
        if (!text) return null; // Return null for empty or undefined text
        return text.replace(/\r/g, '').split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <Wrapper>
            <StyledPropertyOwner className={className}>
                <chakra.div position="relative">
                    <Box
                        position="relative"
                        boxShadow="xl"
                        borderRadius="lg"
                        bg="white"
                        overflow="hidden"
                        mb={4}
                    >
                        {canShowHeader &&
                            <Box
                                pt={2}
                                pb={2}
                                {...paddingSides}
                                color="secondary.800"
                                bg="secondary.500"
                                css={{
                                    '.period, .price-prefix': {
                                        color: 'rgba(255, 255, 255, 0.66)'
                                    }
                                }}
                            >
                                <span style={{fontWeight: '900'}}>
                                    {title ? title :
                                        <FormattedMessage {...messages.title}/>} {marketing['agent']['brandCompanyName'] &&
                                    <span> {marketing['agent']['brandCompanyName']}</span>}
                                </span>
                            </Box>
                        }

                        {!hasMarketing(marketing) &&
                            <Box
                                pb={4}
                                mt={2}
                                position="relative"
                                {...paddingSides}
                            >
                                <chakra.div mt={4} >
                                    <FormattedMessage {...messages.noOwnerFound}/>
                                </chakra.div>
                            </Box>
                        }

                        {hasMarketing(marketing) &&
                            <Box
                                pb={4}
                                mt={2}
                                position="relative"
                                {...paddingSides}
                            >
                                {marketing['agent']['descriptiveName'] &&
                                    <Heading as="h4" mt={4} size="md">{marketing['agent']['descriptiveName']}</Heading>
                                }

                                {marketing['agent']['biography'] &&
                                    <chakra.div mt={2} mb={2}>
                                        {processBiography(marketing['agent']['biography'])}
                                    </chakra.div>
                                }

                                {marketing['agent']['emails'] && marketing['agent']['emails'].map(email =>
                                    <chakra.div>
                                        <AwesomeIcon size="sm" position="relative" icon={faMailbox} mr="3px"/>
                                        <chakra.span ml={1}>
                                            {email}
                                        </chakra.span>
                                    </chakra.div>
                                )}

                                {marketing['agent']['phone'] &&
                                    <chakra.div>
                                        <AwesomeIcon size="sm" position="relative" icon={faPhone} mr="3px"/>
                                        <chakra.span ml={1}>
                                            {marketing['agent']['phone']}
                                        </chakra.span>
                                    </chakra.div>
                                }

                                {specialNotes !== null &&
                                    <chakra.div>
                                        <AwesomeIcon size="sm" position="relative" icon={faSensorAlert} mt={5} />
                                        <chakra.span fontSize={20} ml={1} pb={2}>
                                            <strong>Important Information</strong>
                                            <br/>
                                        </chakra.span>
                                        <chakra.span>
                                            {specialNotes}
                                        </chakra.span>
                                    </chakra.div>
                                }
                            </Box>
                        }

                        <Box
                            pb={4}
                            mt={2}
                            position="relative"
                            {...paddingSides}
                        >
                            <chakra.div mt={4} >
                                <LocaleLink target={"_blank"} to={`/public/${property['id']}/`}>
                                    <Button type="secondary">
                                        <FormattedMessage {...messages.viewPublicListingBtn} />
                                    </Button>
                                </LocaleLink>
                            </chakra.div>
                        </Box>

                    </Box>
                </chakra.div>
            </StyledPropertyOwner>
        </Wrapper>
    );
};

export default PropertyOwnerComponent;
