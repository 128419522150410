import { chakra } from '@chakra-ui/react';

export const StyledReviewRatingAverage = chakra('span', {
  baseStyle: {
    ml: 2,
    color: "textLight",
    fontWeight: "medium",
    fontSize: "sm"
  }
});
