import { compose } from 'redux';
import PropertyOwner from '@rentivo/gatsby-core/src/components/property/PropertyOwner/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import { selectPropertyMarketing } from '@rentivo/gatsby-core/src/selectors/property';
import { createSelector } from "reselect";

const defaultProps = {
  showHeader: true,
  title: null,
  wrapper: ({children}) => children
};

const propertySelector = (state, { type, lang }) => createSelector(
    selectPropertyMarketing,
    (marketing) => {
      type = (type) ? type : defaultProps.type;
      return {
        marketing: marketing,
        property: state,
      };
    }
)(state);

const enhance = compose(
    withProperty(propertySelector)
);

PropertyOwner.defaultProps = defaultProps;

export default enhance(PropertyOwner);
