import { defineMessages } from 'react-intl';

export const scope = 'components.property.PropertyReviews.Review';

export default defineMessages({
  anonymous: {
    id: `${scope}.anonymous`,
    defaultMessage: 'Anonymous',
  },
  defaultTitle: {
    id: `${scope}.defaultTitle`,
    defaultMessage: 'Review',
  },
  stayed: {
    id: `${scope}.stayed`,
    defaultMessage: 'Stayed',
  },
  duration: {
    id: `${scope}.duration`,
    defaultMessage: 'for {value} days',
  },
});
