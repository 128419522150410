import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '@rentivo/gatsby-core/src/containers/PricingProvider/components/MobilePricingWidget/messages';
import Price from '@rentivo/gatsby-core/src/components/property/Price';
import PopoverButton from '@rentivo/gatsby-core/src/components/ui/PopoverButton';
import loadable from '@loadable/component';
import { StyledMobilePricingWidget } from '@rentivo/gatsby-core/src/containers/PricingProvider/components/MobilePricingWidget/styles';

const PricingWidget = loadable(() => import('@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingWidget'));

const MobilePricingWidgetComponent = () => {
  return (
    <StyledMobilePricingWidget>
      <Price/>
      <PopoverButton
        component={PricingWidget}
        button={{
          type: 'primary'
        }}
        showCloseButton={true}
        componentProps={{
          showHeader: false
        }}
        title={<Price/>}
        buttonLabel={<FormattedMessage {...messages.buttonLabel}/>}
      />
    </StyledMobilePricingWidget>
  );
};

export default MobilePricingWidgetComponent;
