import React, { useState } from 'react';
import { HStack, Box } from '@chakra-ui/react';
import { useProps } from '@rentivo/gatsby-core/src/hooks/useProps';
import { useControllableState } from '@chakra-ui/hooks';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faStarHalf } from '@fortawesome/pro-solid-svg-icons/faStarHalf';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';
import { roundHalf } from '@rentivo/gatsby-core/src/utils/numbers';

// TODO: Make interactive

export const defaultProps = {
  min: 0,
  max: 5,
  value: undefined,
  defaultValue: 4,
  disabled: true,
  onChange: (value) => value
};

const Rate = (props) => {
  const { min, max, defaultValue, disabled, value: valueProp, onChange: onChangeProp } = useProps(defaultProps, props);
  const [value, setValue] = useState(roundHalf(defaultValue));
  const [internalValue, handleChange] = useControllableState({
    value: (valueProp) ? roundHalf(valueProp) : roundHalf(value),
    onChange: onChangeProp ? onChangeProp : setValue,
    defaultValue: defaultValue
  });

  const blankStars = [];
  const stars = [];
  for (let i = 1; i <= max; i++) {
    blankStars.push(i);
    if(internalValue >= i) {
      stars.push('full');
    } else if(internalValue === (i - 0.5)) {
      stars.push('half');
    }
  }

  return (
    <Box display="inline-block">
      <Box position="relative" display="inline-block">
        <HStack spacing={1} color="gray.300">
          {blankStars.map(s => <AwesomeIcon size="sm" icon={faStar}/>)}
        </HStack>
        <HStack spacing={1} color="orange.400" position="absolute" top={0} left={0}>
          {stars.map(s => s === 'full' ? <AwesomeIcon size="sm" icon={faStar}/> : <AwesomeIcon size="sm" icon={faStarHalf}/>)}
        </HStack>
      </Box>
    </Box>
  )
};

export default Rate;
