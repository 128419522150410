import React from 'react';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import PropertyReviews from '@rentivo/gatsby-core/src/components/property/PropertyReviews/component';
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext';
import {
  selectPropertyAverageRatingFromReviews,
  selectPropertyReviews
} from '@rentivo/gatsby-core/src/selectors/property';
import { selectLang } from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';
import { withRouter } from '@rentivo/gatsby-core/src/context/RouterContext';

const defaultProps = {
  showTitle: true,
  title: null,
  wrapper: ({children}) => children,
  perPage: 5,
  ratingOutOf: 5,
  lines: 5
};

const propertySelector = (state, { ratingOutOf }) => createSelector(
  selectPropertyReviews,
  selectLang,
  (reviews, locale) => {
    const outOf = (ratingOutOf) ? ratingOutOf : defaultProps.ratingOutOf;
    return {
      reviews,
      locale,
      averageRating: selectPropertyAverageRatingFromReviews(reviews, outOf)
    };
  }
)(state);

const enhance = compose(
  withRouter,
  withProperty(propertySelector)
);

PropertyReviews.defaultProps = defaultProps;

export default enhance(PropertyReviews);
