import { defineMessages } from 'react-intl';

export const scope = 'components.property.PropertyOwner';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Managed by',
  },
  viewPublicListingBtn: {
    id: `${scope}.title`,
    defaultMessage: 'View Public Listing',
  },
  noOwnerFound: {
    id: `${scope}.noOwnerFound`,
    defaultMessage: 'No owner found for this property.',
  }
});
