import React, { useRef, useState } from "react";
import { Row, Col } from "@rentivo/gatsby-core/src/components/ui/Row";
import { Container } from "@rentivo/gatsby-core";
import PricingWidget from "@rentivo/gatsby-core/src/containers/PricingProvider/components/PricingWidget";
import MobilePricingWidget from "@rentivo/gatsby-core/src/containers/PricingProvider/components/MobilePricingWidget";
import PropertyLayout from "@rentivo/gatsby-theme-barcelona/src/components/layouts/PropertyLayout";
import {
  ComputerOrTabletView,
  MobileOnlyView,
} from "@rentivo/gatsby-core/src/context/ResponsiveContext";
import loadable from "@loadable/component";
import PropertyDescription from "@rentivo/gatsby-core/src/components/property/PropertyDescription";
import PropertyFeatures from "@rentivo/gatsby-core/src/components/property/PropertyFeatures";
import PropertyRates from "@rentivo/gatsby-core/src/components/property/PropertyRates";
import PropertyRooms from "@rentivo/gatsby-core/src/components/property/PropertyRooms";
import {
  BATHROOM,
  BEDROOM,
} from "@rentivo/gatsby-core/src/constants/lycanConstants";
import PropertyIncluded from "@rentivo/gatsby-core/src/components/property/PropertyIncluded";
// import PropertyFAQs from "@rentivo/gatsby-core/src/components/property/PropertyFAQs";
import PropertyReviews from "@rentivo/gatsby-core/src/components/property/PropertyReviews";
import InView from "@rentivo/gatsby-core/src/components/generic/InView";
import PropertyPolicies from "@rentivo/gatsby-core/src/components/property/PropertyPolicies";
import PropertyHead from "@rentivo/gatsby-core/src/components/property/PropertyHead";
import { StyledAffix } from "@rentivo/gatsby-theme-barcelona/src/templates/Property/components/PropertyLayout/styles";
import PropertyCancellation from "@rentivo/gatsby-core/src/components/property/PropertyCancellation";
import BlockImageGallery from "@rentivo/gatsby-core/src/components/property/BlockImageGallery";
import PropertyFeefo from "@rentivo/gatsby-core/src/components/property/PropertyFeefo";
import PropertyHeader from "@rentivo/gatsby-core/src/components/property/PropertyHeader";
import { chakra } from "@chakra-ui/react";
import PropertyOwner from "@rentivo/gatsby-core/src/components/property/PropertyOwner";
import PropertyAddress from "@rentivo/gatsby-core/src/components/property/PropertyAddress";

const PropertyMap = loadable(() =>
  import("@rentivo/gatsby-core/src/components/property/PropertyMap")
);
const PropertyAvailability = loadable(() =>
  import("@rentivo/gatsby-core/src/components/pricing/AvailabilityPicker")
);

const PropertyBlockWrapper = ({
  children,
  styleProps = {},
  className = "",
}) => (
  <chakra.div
    mb={6}
    boxShadow={{ base: "none", lg: "md" }}
    borderRadius="md"
    bg={{ base: "transparent", lg: "white" }}
    p={{ base: 0, lg: 6 }}
    className={className}
    {...styleProps}
  >
    {children}
  </chakra.div>
);

const AvailabilityBlockWrapper = ({ children, className = "" }) => (
  <PropertyBlockWrapper
    className={className}
    styleProps={{
      px: "5px",
    }}
  >
    {children}
  </PropertyBlockWrapper>
);

export const PropertyLayoutComponent = ({
  footer,
  updateBar,
  topBar,
  menuItems,
}) => {
  const layoutRef = useRef(null);
  const [container, setContainer] = useState(null);
  return (
    <PropertyLayout
      menuItems={menuItems}
      updateBar={updateBar}
      topBar={topBar}
      ref={layoutRef}
      footer={footer}
    >
      <PropertyHead />
      <BlockImageGallery styleProps={{ mb: 6 }} />
      <div ref={setContainer}>
        <Container>
          <Row>
            <Col colSpan={{ base: 24, md: 16, lg: 17 }}>
              <PropertyHeader styleProps={{ mb: 6 }} />
              <PropertyDescription wrapper={PropertyBlockWrapper} />
              <PropertyRooms type={BEDROOM} wrapper={PropertyBlockWrapper} />
              <PropertyRooms type={BATHROOM} wrapper={PropertyBlockWrapper} />
              <PropertyIncluded wrapper={PropertyBlockWrapper} />
              <PropertyFeatures wrapper={PropertyBlockWrapper} />
              {/* <PropertyFAQs wrapper={PropertyBlockWrapper} /> */}
              <InView>
                <PropertyMap forceExactLocation={true} wrapper={PropertyBlockWrapper} />
                <PropertyAvailability wrapper={AvailabilityBlockWrapper} />
              </InView>
              <PropertyRates wrapper={PropertyBlockWrapper} />
              <PropertyReviews wrapper={PropertyBlockWrapper} />
              <PropertyFeefo wrapper={PropertyBlockWrapper} />
              {/*<PropertyCancellation wrapper={PropertyBlockWrapper} />*/}
              <PropertyPolicies wrapper={PropertyBlockWrapper} />
            </Col>
            <Col colSpan={{ base: 24, md: 8, lg: 7 }} innerHeight="100%">
              <ComputerOrTabletView>
                <StyledAffix>
                  <PricingWidget />
                  <PropertyOwner />
                </StyledAffix>
              </ComputerOrTabletView>
            </Col>
          </Row>
        </Container>
      </div>

      <MobileOnlyView>
        <MobilePricingWidget />
      </MobileOnlyView>
    </PropertyLayout>
  );
};

export default PropertyLayoutComponent;
