import { defineMessages } from 'react-intl';

export const scope = 'components.property.PropertyReviews';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: '{reviewCount, plural, =0 {1 Review} one {1 Review} other {{reviewCount} Reviews}}',
  }
});
