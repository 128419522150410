import { defineMessages } from 'react-intl';

export const scope = 'components.property.PropertyReviews.ReviewsSummary';

export default defineMessages({
  averageRating: {
    id: `${scope}.averageRating`,
    defaultMessage: 'Average rating: ',
  },
  summaryPage: {
    id: `${scope}.reviewsSummaryPage`,
    defaultMessage: '{to}—{from} of {total}'
  },
  averageSummary: {
    id: `${scope}.reviewsAverageSummary`,
    defaultMessage: '{rating}',
  },
});
